<template>
  <div>
    <DxDataGrid
      :ref="gridRefName"
      :data-source="orders"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :columnAutoWidth="true"
      :allow-column-resizing="true"
      :allow-column-reordering="true"
      id="settlementTable"
    >
      <DxExport :enabled="true" />
      <DxColumnChooser
        emptyPanelText="Arrastre una columna aquí para ocultar"
        :enabled="true"
        title="Arrastre columna aquí..."
      />
      <DxColumnFixing :enabled="true" />
      <DxGroupPanel
        :visible="true"
        emptyPanelText="Arrastre una columna aquí para agrupar"
      />
      <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
      <DxPaging :page-size="30" />
      <DxPager
        :visible="true"
        infoText="Página {0} de {1} ({2} registros)"
        :allowed-page-sizes="[5, 15, 30, 50]"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <DxHeaderFilter :visible="true" :allowSearch="true" />
      <DxFilterRow :visible="true">
        <DxOperationDescriptions
          equal="Igual a"
          notEqual="Diferente de"
          startsWith="Empieza con"
          endsWith="Termina con"
          contains="Contiene"
          between="Entre"
          notContains="No contiene"
          lessThanOrEqual="Menor o igual"
          greaterThanOrEqual="Mayor o igual"
          lessThan="Menor que"
          greaterThan="Mayor que"
        />
      </DxFilterRow>
      <DxGroupPanel :visible="true" />
      <DxColumn caption="Num OT" alignment="center" data-field="num_ot" />
      <DxColumn caption="Num Aviso" alignment="center" data-field="num_aviso" />
      <DxColumn
        caption="Dirección"
        alignment="center"
        data-field="dir_suscriptor"
      />
      <DxColumn
        caption="Tipo aviso"
        data-field="tipo_aviso"
        :width="250"
      />

      <DxColumn data-field="brigada" caption="Cod brigada" />
      <DxColumn data-field="observacion" caption="Observacion" />

      <DxColumn data-field="estado_o_t_s.descripcion" caption="Estado" />
      <DxColumn data-field="fecha_asigna" caption="Fecha asignación" />
      <DxColumn
        data-field="user.nombre"
        caption="Nombre brigada"
        :group-index="0"
      />

      <DxMasterDetail 
        :enabled="true"
        template="master-detail"
      />

      <template #master-detail="{ data }">
      <div>
        <OrderDetails :id-ot="data.data.id_ot"/>
      </div>
      </template>


      <DxSummary>
        <DxGroupItem
          column="num_ot"
          summary-type="count"
          display-format="{0} trabajos"
          :align-by-column="true"
        />
      </DxSummary>
    </DxDataGrid>
  </div>
</template>
<style>
#settlementTable {
  max-height: 700px !important;
}
</style>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxFilterRow,
  DxColumnFixing,
  DxHeaderFilter,
  DxOperationDescriptions,
  DxColumnChooser,
  DxExport,
  DxSummary,
  DxGroupItem,
  DxMasterDetail,
} from "devextreme-vue/data-grid";

import OrderDetails from './OrderDetails.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxFilterRow,
    DxColumnFixing,
    DxHeaderFilter,
    DxOperationDescriptions,
    DxColumnChooser,
    DxExport,
    DxSummary,
    DxGroupItem,
    DxMasterDetail,
    OrderDetails
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      gridRefName: "dataGrid1",
    };
  },
};
</script>
