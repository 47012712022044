<template>
  <div>
    <b-button
      id="kt_quick_position_toggle"
      size="sm"
      variant="info"
      @click="tooglePositionPanel()"
      v-if="isLoading"
      >posiciones</b-button
    >
    <div
      id="kt_quick_position"
      :class="`offcanvas offcanvas-right p-10 ${getGps ? 'offcanvas-on' : ''}`"
    >
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          RECORRIDO GPS
          <small class="text-muted font-size-sm ml-2"></small>
        </h3>
      </div>
      <div>
        <button class="btn btn-sm btn-link" @click="tooglePositionPanel()">
          <i class="la la-close"></i> Cerrar
        </button>
      </div>

      <div class="offcanvas-content pr-5 mr-n5">
        <div class="navi navi-spacer-x-0 p-0">
          <div class="navi-item">
            <b-row class="m-3">
              <BrigadeTableLocations
                ref="BREPORT2"
                :dataLocations="dataLocations"
                @getChoosePosition="onChoosePosition"
              />
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <!-- <modal-draggable
      name="hello-world"
      :draggable="true"
      :width="550"
      :height="350"
      :resizable="true"
      :adaptive="true"
    >
      <b-row class="m-3 d-flex justify-content-center align-items-center">
        <b-col>
          <b-button size="sm" variant="link" class="text-dark" @click="hide">
            <i class="la la-window-close text-dark"></i> Cerrar
          </b-button>
        </b-col>
        <b-col>
          <span> Tabla posiciones </span>
        </b-col>
      </b-row>
      <b-row class="m-3">
        <BrigadeTableLocations
          ref="BREPORT2"
          :dataLocations="dataLocations"
          @getChoosePosition="onChoosePosition"
        />
      </b-row>
    </modal-draggable> -->
  </div>
</template>

<script>
import BrigadeTableLocations from "./BrigadeTableLocations.vue";
import * as $ from "jquery";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "brigade-draggable-modal",
  components: { BrigadeTableLocations },
  props: {
    dataLocations: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getGps: "technical/getIsGps",
    }),
  },
  methods: {
    ...mapActions({
      isGps: "technical/setIsGps",
    }),
    tooglePositionPanel() {
      console.log(this.getGps);
      this.isGps(!this.getGps);
      if (!this.getGps) {
        $("body").removeClass("offcanvas-on");
      } else {
        $("body").addClass("offcanvas-on");
      }
    },
    onChoosePosition(data) {
      this.$emit("getPosition", data);
    },
  },
};
</script>
<style scoped>
.modal-header {
  background-color: #18456b;
}
</style>
