<template>
  <div>
    <b-row v-if="dataAddres.length">
      <b-col>
        <DxDataGrid
          :data-source="dataAddres"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          id="brigade-report[1]"
        >
          <DxColumnChooser
            emptyPanelText="Arrastre una columna aquí para ocultar"
            :enabled="true"
            title="Arrastre columna aquí..."
          />
          <DxColumnFixing :enabled="true" />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />

          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxPaging :page-size="30" />
          <DxPager
            :visible="true"
            infoText="Página {0} de {1} ({2} registros)"
            :allowed-page-sizes="[5, 15, 30, 50]"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxGroupPanel :visible="true" />
          <DxColumn caption="Fecha" alignment="center" data-field="date" />
          <DxColumn
            caption="Direccion"
            alignment="center"
            data-field="addres"
          />
          <DxColumn
            caption="Municipio"
            alignment="center"
            data-field="departament"
          />
        </DxDataGrid>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="d-flex justify-content-center flex-colunm">
          <div class="text-center">
            <img src="assets/img/empty.png" alt="Empty" />
            <p class="text-muted">No hay información para mostrar</p>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxPager,
  DxGroupPanel,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
} from "devextreme-vue/data-grid";

export default {
  name: "BrigadeReport",
  components: {
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxPager,
    DxGroupPanel,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
  },
  props: {
    dataAddres: {
      type: Array,
      default: () => []
    },
  }
};
</script>
