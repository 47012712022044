<template>
  <b-col v-if="dataLocations.length">
    <DxDataGrid
      :data-source="dataLocations"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :columnAutoWidth="true"
      :allow-column-resizing="true"
      :allow-column-reordering="true"
      id="tableReport"
    >
      <DxColumnChooser
        emptyPanelText="Arrastre una columna aquí para ocultar"
        :enabled="true"
        title="Arrastre columna aquí..."
      />
      <DxColumnFixing :enabled="true" />
      <DxGroupPanel
        :visible="true"
        emptyPanelText="Arrastre una columna aquí para agrupar"
      />

      <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
      <DxPaging :page-size="100" />
      <DxPager
        :visible="false"
        infoText="Página {0} de {1} ({2} registros)"
        :allowed-page-sizes="[5, 15, 30, 50, 'all']"
        display-mode="full"
        :show-page-size-selector="true"
        :show-info="true"
        :show-navigation-buttons="true"
      />
      <DxGroupPanel :visible="false" />
      <DxColumn
        alignment="center"
        caption="Acción"
        type="buttons"
        cell-template="cellTemplate"
      />

      <DxColumn caption="Latitud" alignment="center" data-field="lat" />
      <DxColumn caption="Longitud" alignment="center" data-field="lng" />
      <DxColumn caption="Fecha" alignment="center" data-field="fullDate" />

      <template #cellTemplate="{ data: cellData }">
        <!-- <b-button size="sm" variant="link" > -->
        <i
          class="la la-eye text-primary eye-button"
          @click="getDataCell(cellData)"
        ></i>
        <!-- </b-button> -->
      </template>
    </DxDataGrid>
  </b-col>
  <b-col v-else>
    <div class="d-flex justify-content-center flex-colunm">
      <div class="text-center">
        <img src="assets/img/empty.png" alt="Empty" />
        <p class="text-muted">No hay información para mostrar</p>
      </div>
    </div>
  </b-col>
</template>
<style>
#tableReport {
  max-height: 100vh !important;
  height: 60vh !important;
}
.eye-button:hover {
  cursor: pointer;
}
</style>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxPager,
  DxGroupPanel,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
} from "devextreme-vue/data-grid";

export default {
  name: "BrigadeReport",
  components: {
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxPager,
    DxGroupPanel,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
  },
  props: {
    dataLocations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeMarker: [],
    };
  },
  methods: {
    onClickRow() {
      console.log("click");
    },
    getDataCell(data) {
      this.activeMarker = data.values.filter((e) => e !== null);
      this.$emit("getChoosePosition", this.activeMarker);
    },
  },
};
</script>
