const infoWindow = (el, ot) => {
    const html = `<div id="content" style="width: 600px !important;">
                    <div id="bodyContent">
                        <strong>
                            <div class="text-center mb-1">
                                <p>INFORMACIÓN DE OT</p>
                            </div>
                            <b><span class="text-danger">N° OT :</span> ${ot.num_ot}</b> <br> 
                            <b><span class="text-danger">Observación :</span> ${ot.observacion}</b> <br> 
                            <b><span class="text-danger">Brigada :</span> ${ot.user == null ? "NO ASIGNADO" : ot.user.nombre}</b> <br> 
                            <b><span class="text-danger">Estado :</span> <i class="${ot.estado_o_t_s.icono}"></i> ${ot.estado_o_t_s.descripcion}</b> <br> 
                            <hr>
                            <div class="text-center mb-1">
                                <p>INFORMACIÓN DE AVISO DE REFERENCIA</p>
                            </div>
                            <div style="font-weight: bold;">
                                <span class="text-danger">N° de aviso :</span> ${el.num_aviso} <br>
                                <span class="text-danger">Subestación :</span> ${el.subestacion} <br>
                                <span class="text-danger">Circuito :</span> ${el.circuito_sm}
                            </div> 
                                <div class="separator separator-dashed mt-1 mb-1"></div>

                            <b><span class="text-danger">Nombre de suscriptor :</span> ${el.nombre_suscriptor}</b> <br> 
                            <b><span class="text-danger">Télefono: :</span> ${el.telefono_suscriptor}</b> <br> 
                            <div class="separator separator-dashed mt-1 mb-1"></div>
                            <b><span class="text-danger">Tipo de aviso :</span> ${el.tipo_aviso}</b> <br> 
                            <b><span class="text-danger">Descripción:</span> ${el.observacion}</b> <br> 
                            <b><span class="text-danger">Referencia:</span> ${el.dir_ref}</b> <br> 
                            <b><span class="text-danger">Dirección:</span> ${el.dir_suscriptor}</b>
                        </strong>
                    </div>
                </div>`;
    return html;


}
export default infoWindow