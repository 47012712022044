import moment from "moment"

const TechRunning = (worker) => {
    return ` <strong>
                <span style="color: #F64E60; font-weight: bold;">Brigada:</span> ${worker.nombre} <br> 
                <span style="color: #F64E60; font-weight: bold;">Movil:</span> <a href="tel:${worker.movil}"> ${worker.movil} </a> <br> 
                <span style="color: #F64E60; font-weight: bold;">Identificación:</span> ${worker.usuario} <br> 
                <span style="color: #F64E60; font-weight: bold;">Vehiculo:</span> ${worker.vehiculo.toUpperCase()} <br> 
                <span style="color: #F64E60; font-weight: bold;">Tipo de vehículo:</span> ${worker.tipo_vehiculo} <br> 
                ${worker.usuario_sesion ? moment(`${worker.usuario_sesion.fecha}`).format('llll') : ''} <br> 
            </strong>`
}
export default TechRunning